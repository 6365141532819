// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap';
import '../stylesheets/application';
import 'jscroll';
import '@fortawesome/fontawesome-free/js/all'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(window).on('scroll', function() {
  let test = $('ul.pagination li a[rel="next"]:first');
  let scrollHeight = $(document).height();
  let scrollPosition = $(window).height() + $(window).scrollTop();
  if ( (scrollHeight - scrollPosition) / scrollHeight <= 0.05) {
    $('.jscroll').jscroll({
      oadingHtml: 'Loading...',
      contentSelector: '.scroll-list',
      nextSelector: 'ul.pagination li a.page-link[rel="next"]:first'
    });
  }
});

$(function() {
  $('button.release-all-btn').on('click', function(){
    event.preventDefault();
    $('input[type=checkbox]').removeAttr("checked").prop("checked", false).change();
  });

  $('button.release-category-btn').on('click', function(){
    event.preventDefault();
    $('ul.category-list input[type=checkbox]').removeAttr("checked").prop("checked", false).change();
  });

  $('button.release-brand-btn').on('click', function(){
    event.preventDefault();
    $('ul.brand-list input[type=checkbox]').removeAttr("checked").prop("checked", false).change();
  });

  $('button.release-shop-btn').on('click', function(){
    event.preventDefault();
    $('ul.shop-list input[type=checkbox]').removeAttr("checked").prop("checked", false).change();
  });

  $('input[name="q[master_category_id_eq_any][]"]').on('click', function(){
    let masterCategoryId = $(this).val();
    if( $(this).prop("checked") != true ) {
      $('ul#category' + masterCategoryId + ' input').removeAttr("checked").prop("checked", false).change();
    } else {
      $('ul#category' + masterCategoryId + ' input').attr('checked', true).prop('checked', true).change();
    }
  });


});
